import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import 'react-phone-input-2/lib/style.css'
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from '../../blocks/adminconsole2/src/Adminconsole2';
import Catalogue from '../../blocks/catalogue/src/CatalogueVendor.web';
import BulkUploading from '../../blocks/bulkuploading/src/BulkUploading';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import LandingPageWeb from '../../blocks/landingpage/src/LandingPage.web';
import Analytics from '../../blocks/analytics/src/Analytics';
import Interactivefaqs from '../../blocks/interactivefaqs/src/Interactivefaqs';
import AddInteractivefaqs from '../../blocks/interactivefaqs/src/AddInteractivefaqs';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import SocialMediaAccountLogin from '../../blocks/social-media-account/src/SocialMediaAccountLogin';
import SocialMediaAccountRegistration from '../../blocks/social-media-account/src/SocialMediaAccountRegistration';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import Reviews from '../../blocks/reviews/src/Reviews';
import AddReview from '../../blocks/reviews/src/AddReview';
import Uploadmedia3 from '../../blocks/uploadmedia3/src/Uploadmedia3';
import Contactus from '../../blocks/contactus/src/Contactus';
import NewContactUs from '../../blocks/contactus/src/NewContactUs.web';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Videos from '../../blocks/videos/src/Videos';
import Notifications from '../../blocks/notifications/src/Notifications';
import Trending from '../../blocks/trending/src/Trending';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import Maps from '../../blocks/maps/src/Maps';
import ShoppingCartOrders from '../../blocks/shoppingcart/src/ShoppingCartOrders';
import AddShoppingCartOrderItem from '../../blocks/shoppingcart/src/AddShoppingCartOrderItem';
import AdvancedSearch from '../../blocks/advancedsearch/src/AdvancedSearch';
import InventoryManagement from '../../blocks/inventorymanagement/src/InventoryManagement.web';
import Cfservicesrecommendation from '../../blocks/cfservicesrecommendation/src/Cfservicesrecommendation';
import UserProfile from '../../blocks/settings2/src/UserProfile.web';
import OrderManagement from '../../blocks/ordermanagement/src/OrderManagementView.web';
import Scheduling from '../../blocks/scheduling/src/Scheduling';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import Rolesandpermissions2 from '../../blocks/rolesandpermissions2/src/Rolesandpermissions2';
import AutomaticCheckoutCalculation from '../../blocks/automaticcheckoutcalculation/src/AutomaticCheckoutCalculation';
import StripePayments from '../../blocks/stripepayments/src/StripePayments';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import NewPasswordWeb from '../../blocks/forgot-password/src/NewPassword.web';
import PasswordConfirmation from '../../blocks/forgot-password/src/PasswordConfirmation.web';
import Sorting from '../../blocks/sorting/src/Sorting';
import Settings2 from '../../blocks/settings2/src/Settings2.web';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import Customisableusersubscriptions from '../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions';
import SubscriptionDetails from '../../blocks/customisableusersubscriptions/src/SubscriptionDetails';
import SubscriptionVendor from '../../blocks/customisableusersubscriptions/src/SubscriptionVendor.web';
import CreateVendorOffer from '../../blocks/customisableusersubscriptions/src/CreateVendorOffer.web';
import Chat from '../../blocks/chat/src/Chat.web';
import ViewChat from '../../blocks/chat/src/ViewChat';
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions';
import TermsConditionsDetail from '../../blocks/termsconditions/src/TermsConditionsDetail';
import TermsConditionsUsers from '../../blocks/termsconditions/src/TermsConditionsUsers';
import Share from '../../blocks/share/src/Share';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import Paymentadmin2 from '../../blocks/paymentadmin2/src/Paymentadmin2';
import Emailnotifications2 from '../../blocks/emailnotifications2/src/Emailnotifications2';
import EmailAccount from '../../blocks/email-account-login/src/EmailAccount.web';
import ShowVerification from '../../blocks/email-account-login/src/ShowVerification.web';
import ShowOtp from '../../blocks/email-account-login/src/ShowOtp.web';
import Welcome from '../../blocks/email-account-login/src/Welcome.web';
import LoginEmail from '../../blocks/email-account-login/src/LoginEmail.web';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-multi-carousel/lib/styles.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import VendorLogin from '../../blocks/email-account-login/src/VendorLogin.web';
import VendorSignup from '../../blocks/email-account-login/src/VendorSignup.web';
import VendorPhoneLogin from '../../blocks/email-account-login/src/VendorPhoneLogin.web';
import VendorOTPLogin from '../../blocks/email-account-login/src/VendorOTPLogin.web';
import VendorForgotPassword from '../../blocks/forgot-password/src/VendorForgotPassword.web';
import Faqs from '../../blocks/interactivefaqs/src/Faqs.web';
import Inspirations from '../../blocks/contentmanagement/src/Inspirations.web';
import InspirationsItem from '../../blocks/contentmanagement/src/InspirationsItem.web';
import RealWedding from '../../blocks/contentmanagement/src/RealWedding.web';
import RealWeddingItems from '../../blocks/contentmanagement/src/RealWeddingItems.web';
import AddCatalogueForm from '../../blocks/multipageforms/src/AddCatalogueForm.web';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';

const routeMap = {
  LoginEmail: {
    component: LoginEmail,
    path: '/login',
    exact: true,
  },
  VendorLogin: {
    component: VendorLogin,
    path: '/vendorLogin',
    exact: true,
  },
  AddCatalogueForm:{
    component: AddCatalogueForm,
    path: '/add-catalogue',
  },
  Welcome: {
    component: Welcome,
    path: '/welcome',
  },
  ShowOtp: {
    component: ShowOtp,
    path: '/otp/:id?',
  },
  NewPasswordWeb: {
    component: NewPasswordWeb,
    path: '/new-password',
  },
  PasswordConfirmation: {
    component: PasswordConfirmation,
    path: '/password-confirmation',
    exact: true,
  },
  ShowVerification: {
    component: ShowVerification,
    path: '/show-verification',
    exact: true,
  },
  EmailAccount: {
    component: EmailAccount,
    path: '/sign-up',
    exact: true,
  },
  VendorSignup: {
    component: VendorSignup,
    path: '/vendorSignup',
    exact: true,
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard',
    exact: true,
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2',
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue',
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading',
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage',
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics',
  },
  Interactivefaqs: {
    component: Interactivefaqs,
    path: '/Interactivefaqs',
  },
  AddInteractivefaqs: {
    component: AddInteractivefaqs,
    path: '/AddInteractivefaqs',
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth',
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput',
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm',
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector',
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration',
  },
  VendorPhoneLogin: {
    component: VendorPhoneLogin,
    path: '/vendorPhoneLogin',
  },
  VendorOTPLogin: {
    component: VendorOTPLogin,
    path: '/vendorOTPLogin',
  },
  VendorForgotPassword: {
    component: VendorForgotPassword,
    path: '/vendorForgotPassword',
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock',
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: '/SocialMediaAccountLogin',
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: '/SocialMediaAccountRegistration',
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile',
  },
  Reviews: {
    component: Reviews,
    path: '/Reviews',
  },
  AddReview: {
    component: AddReview,
    path: '/AddReview',
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: '/Uploadmedia3',
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus',
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus',
  },
  Videos: {
    component: Videos,
    path: '/Videos',
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications',
  },
  Trending: {
    component: Trending,
    path: '/Trending',
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems',
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions',
  },
  Maps: {
    component: Maps,
    path: '/Maps',
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: '/ShoppingCartOrders',
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: '/AddShoppingCartOrderItem',
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch',
  },
  InventoryManagement: {
    component: InventoryManagement,
    path: '/InventoryManagement',
  },
  Cfservicesrecommendation: {
    component: Cfservicesrecommendation,
    path: '/Cfservicesrecommendation',
  },
  UserProfileBasicBlock: {
    component: UserProfile,
    path: '/UserProfile',
  },
  OrderManagement: {
    component: OrderManagement,
    path: '/OrderManagement',
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling',
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu',
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: '/Rolesandpermissions2',
  },
  AutomaticCheckoutCalculation: {
    component: AutomaticCheckoutCalculation,
    path: '/AutomaticCheckoutCalculation',
  },
  StripePayments: {
    component: StripePayments,
    path: '/StripePayments',
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/forgot-password',
    exact: true,
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP',
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword',
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting',
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2',
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications',
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: '/Customisableusersubscriptions',
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: '/SubscriptionDetails',
  },
  SubscriptionVendor: {
    component: SubscriptionVendor,
    path: '/Offers',
  },
  CreateVendorOffer:{
    component: CreateVendorOffer,
    path: '/CreateOffer',
  },
  Chat: {
    component: Chat,
    path: '/Chat',
  },
  ViewChat: {
    component: ViewChat,
    path: '/ViewChat',
  },
  TermsConditions: {
    component: TermsConditions,
    path: '/TermsConditions',
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: '/TermsConditionsDetail',
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: '/TermsConditionsUsers',
  },
  Share: {
    component: Share,
    path: '/Share',
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories',
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: '/Paymentadmin2',
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: '/Emailnotifications2',
  },
  LandingPageWeb: {
    path: '/',
    exact: true,
    component: LandingPageWeb,
  },
  Faqs: {
    path: '/faq',
    exact: true,
    component: Faqs,
  },
  NewContactUs: {
    component: NewContactUs,
    path: '/contact-us',
    exact: true,
  },
  Inspirations: { component: Inspirations, path: '/inspirations', exact: true },
  InspirationsItem: {
    component: InspirationsItem,
    path: '/inspirations/:id',
    exact: true,
  },
  RealWedding: { component: RealWedding, path: '/real-wedding', exact: true },
  RealWeddingItems: {
    component: RealWeddingItems,
    path: '/real-wedding/:id',
    exact: true,
  },
  Home: {
    component: HomeScreen,
    path: '/home',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },

  Alert: {
    component: AlertBlock,
    path: '*/Alert',
    modal: true,
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 480,
      sm: 600,
      md: 980,
      lg: 1280,
      xl: 1536,
    },
  },
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <BuilderProvider>
        <ThemeProvider theme={theme}>
          <View style={{ height: '100%', width: '100%' }}>
            <ToastContainer
              autoClose={3000}
              position="top-right"
              className="toast-container"
              toastClassName="dark-toast"
            />
            {WebRoutesGenerator({ routeMap })}
            <ModalContainer />
          </View>
        </ThemeProvider>
      </BuilderProvider>
    );
  }
}

export default App;
