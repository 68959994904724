import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { apiCall } from "../../../components/src/utils";
import { toast } from "react-toastify";

export const baseURL = require("../../../framework/src/config.js");

interface IFaqIcon  {
  id: number,
  type: string,
  attributes: {
    id: number,
    title: string,
    select_img: string,
    unselect_img: string,
    questions_answers: IFaqQuestionAnswer[];
  }
}

interface IFaqQuestionAnswer {
  id: number;
  question: string;
  answer: string;
  created_at: string;
  admin_users_id: number;
  updated_at: string;
  faq_icons_id: number;
}

type FaqQuesANs = {
  data: [
    {
      id: number,
      type: string,
      attributes: {
        id: number,
        title: string,
        select_img: string,
        unselect_img: string,
        questions_answers: IFaqQuestionAnswer[];
      }
    }
  ]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  activeItem: { id: number; title: string };
  faqIcons: IFaqIcon[];
  faqQuestionAnswer: IFaqQuestionAnswer[];

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FaqsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  faqIconsApiCallId: string = "";
  faqQuesAnsApiCallId: string = "";
  faqVendorQuesAnsApiCallId:string="";
  getFaqListCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      activeItem: { id: 0, title: "" },
      faqQuestionAnswer: [],
      faqIcons: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        
      );
      switch (webApiRequestCallId) {
        case this.faqVendorQuesAnsApiCallId:
          this.handleFaqQuestionAnswerResponse(webResponseJson);
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    this.getVendorFaqQuesAns();
  }

  setActiveItem = (faqSelected:IFaqIcon) => {
    this.setState({ activeItem: { id: faqSelected.attributes.id, title:faqSelected.attributes.title },faqQuestionAnswer:faqSelected.attributes.questions_answers }, () => {
    });
  };

  handleFaqQuestionAnswerResponse = (webResponseJson: FaqQuesANs) => {
    if (webResponseJson.data && webResponseJson.data.length > 0) {
      this.setState(
        {
          faqIcons: webResponseJson.data,
          isLoading: false,
        },
      );
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  getVendorFaqQuesAns = async () => {
    let token = localStorage.getItem("authToken")
    let header:any = {
      "Content-Type": "application/json",
    };
    if(token){
      header = {
        "Content-Type": "application/json",
        token
      };
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.faqGetAPiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); this.faqVendorQuesAnsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
