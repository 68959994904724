import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface FunctionAttributes {
  id: number;
  name: string;
  function_image: string | null;
}

interface Function {
  id: string;
  type: string;
  attributes: FunctionAttributes;
}

interface ServiceLocationAttributes {
  id: number;
  location_name: string;
}

interface ServiceLocation {
  id: string;
  type: string;
  attributes: ServiceLocationAttributes;
}

interface ServiceLocations {
  data: ServiceLocation[];
}

interface CustomFields {
  color: string;
  material: string;
}

interface ProductAttributes {
  id: number;
  name: string;
  SKU: string;
  description: string;
  fabric_details: string;
  price: string;
  available_quantity: number;
  media_files: any[];
  functions: Function[];
  service_locations: ServiceLocations;
  product_variants: any[];
  custom_fields: CustomFields;
  created_at: string;
  is_published: boolean;
  product_faqs: any[];
  size_chart: string | null;
  is_available: boolean;
  is_deleted: boolean;
}

export interface Product {
  id: string;
  type: string;
  attributes: ProductAttributes;
}

interface ProductListData {
  data: Product[];
}
interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  anchorFilterEl: HTMLElement | null;
  productListData:ProductListData[];
  moreBtnId:number;
  editData:Product
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueVendorController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  getProductListApiCallId:string = "";
  deleteProductApiCallId:string=""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      anchorEl: null,
      anchorFilterEl:null,
      productListData:[],
      moreBtnId:0,
      editData:{
        id: "",
        type: "",
        attributes: {
          id: 0,
          name: "",
          SKU: "",
          description: "",
          fabric_details: "",
          price: "",
          available_quantity: 0,
          media_files: [],
          functions: [],
          service_locations: {
            data: []
          },
          product_variants: [],
          custom_fields: {
            color: "",
            material: ""
          },
          created_at: "",
          is_published: false,
          product_faqs: [],
          size_chart: null,
          is_available: false,
          is_deleted: false
        }
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getProductListApiCall()
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.getProductListApiCallId){
        let removeDeletedProduct = responseJson.data.filter((data:Product) => {
          return data.attributes.is_deleted === false
        })
          this.setState({ productListData: removeDeletedProduct });
    }
    if(apiRequestCallId === this.deleteProductApiCallId){
      this.getProductListApiCall()
  }
    // Customizable Area End
  }

  // Customizable Area Start
  getToken = async () => {
    const token = await getStorageData("authToken");
    if (token) {
      this.setState({
        token,
      });
    } else {
      this.props.navigation.goBack();
    }
  };
  goTaddCatalogue = () => {
    setStorageData("IsEditing", false)
    const addCatalogueForm: Message = new Message(getName(MessageEnum.NavigationMessage));
        addCatalogueForm.addData(getName(MessageEnum.NavigationTargetMessage), "AddCatalogueForm");
        addCatalogueForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(addCatalogueForm);
  };
  handleClick = (event: React.MouseEvent<HTMLElement>, moreData:Product) => {
    this.setState({ anchorEl: event.currentTarget, moreBtnId:moreData.attributes.id, editData: moreData });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorFilterEl: event.currentTarget });
  };

  handleFilterClose = () => {
    this.setState({ anchorFilterEl: null });
  };
  getProductListApiCall = async () => {
    this.getProductListApiCallId = await apiCall({
      endPoint: "bx_block_multipageforms2/products",
      method: "GET",
      token: this.state.token,
    });
  }
  deleteProductApiCall = async () => {
    this.setState({ anchorEl: null });
    this.deleteProductApiCallId = await apiCall({
      endPoint: `bx_block_multipageforms2/products/${this.state.moreBtnId}`,
      method: "DELETE",
      token: this.state.token,
    });
  }
  handleEditBtn = () => {
    setStorageData("editId", this.state.moreBtnId)
    setStorageData("IsEditing", true)
    const addCatalogueForm: Message = new Message(getName(MessageEnum.NavigationMessage));
    addCatalogueForm.addData(getName(MessageEnum.NavigationTargetMessage), "AddCatalogueForm");
    addCatalogueForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(addCatalogueForm);
  }
  // Customizable Area End
}
