import React from "react";
// Customizable Area Start
import { Box, Typography ,Button, Table,TableHead,TableCell,TableRow,TableBody, styled, IconButton, Popover} from "@mui/material";
import HeaderVendorFooter from "../../../../packages/components/src/HeaderVendorFooter";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
// Customizable Area End

import SubscriptionVendorController, { Props} from "./SubscriptionVendorController.web";

export default class SubscriptionVendor extends SubscriptionVendorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { anchorEl, discountOfferData} = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <HeaderVendorFooter navigation={this.props.navigation}>
        <CustomBox>
        <Box className="mainHeading">
          <Typography className="offerHead">Offers</Typography>
          <Button onClick={this.goToCreateVendorPage} data-test-id="goToCreateVendorPage" className="addCatalogueBtn">+ Create Offer</Button>
        </Box>
        <Box sx={webStyle.contentHeading}>
          <Box className="mainContainer">
            <Table aria-label="a dense table" style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid lightgrey' }}>
              
              <TableHead style={{ borderLeft: '1px solid transparent', borderRight: "1px solid transparent", borderTop: "1px solid transparent" }}>
               <TableRow>
               <TableCell  className="tableHead" style={{width:"5%"}}>#</TableCell>
                <TableCell  className="tableHead" style={{width:"12%"}}>Coupon code</TableCell>
                  <TableCell className="tableHead"style={{width:"12%"}}>Exp Date</TableCell>
                  <TableCell className="tableHead"style={{width:"12%"}}>Order value</TableCell>
                  <TableCell className="tableHead"style={{width:"15%"}}>Max Discount</TableCell>
                  <TableCell className="tableHead"style={{width:"5%"}}>Offer %</TableCell>
                  <TableCell className="tableHead" style={{width:"15%"}}>Terms & Policy</TableCell>
                  <TableCell className="tableHead"style={{width:"15%"}}>Status</TableCell>
                  <TableCell className="tableHead"style={{width:"15%"}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ border: "1px solid lightgrey" }}>
                {discountOfferData? this.state.discountOfferData.map((product:any, index:number) => (
                  <TableRow key={index} >
                       <TableCell className="tableCellData">{product.id}</TableCell>
                        <TableCell className="tableCellData">{product.attributes.coupon_code}</TableCell>
                    <TableCell className="tableCellData">{product.attributes.exp_date}
                    </TableCell>
                    <TableCell className="valueStyle"><span><CurrencyRupeeIcon fontSize="small" /></span><span>{product.attributes.min_order_value}</span></TableCell>
                    <TableCell className="tableCellData">{product.attributes.max_discount}</TableCell>
                    <TableCell className="tableCellData"><span className="offerStyle">{product.attributes.discount_percentage}%</span></TableCell>
                    <TableCell className="tableCellData" style={{ width: "20%" }} title={product.attributes.terms_policies} >
  {product.attributes.terms_policies.length > 15
    ? `${product.attributes.terms_policies.substring(0, 15)}...`
    : product.attributes.terms_policies}
</TableCell>

                    <TableCell className="tableCellData"><span className="offerStyle">Active</span></TableCell>
                    <TableCell>
        <IconButton aria-describedby={id} data-test-id="handleClick" onClick={(event) => this.handleClick(event)}>
          <MoreVertIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              boxShadow: "#CECECE 0px 2px 1px",
            },
          }}
        >
          <Box sx={{display: 'flex', flexDirection: 'column',gap:1, width:"100px"}}>
            <Button sx={webStyle.sectonBtn} data-test-id="handleEditOffer" onClick={this.handleEditOffer}>Edit</Button>
          </Box>
        </Popover>
      </TableCell>
                    <TableCell>
                    </TableCell>
                  </TableRow>
                ))
                :
                <Typography>No Data Found</Typography>}
              </TableBody>
            </Table>
          </Box>
        </Box>
        </CustomBox>
      </HeaderVendorFooter>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  contentHeading:{
    margin: "0px 2.5rem",
    display:"flex", 
    flexDirection:"column",
    gap:"2rem"
  },
  sectonBtn:{
    textTransform:"capitalize",
    textAlign:"left",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    color:"#78716C"
  },
};
const CustomBox = styled(Box)({
    "& .offerHead":{
        fontFamily: "Poppins",
        fontSize: "20px",
        lineHeight: "24px",
        fontWeight:600,
        paddingLeft:"20px"
    },
"& .tableHead":{
  fontFamily: "Poppins",
fontSize: "16px",
lineHeight: "24px",
width:"10%",
color:"#78716C",
fontWeight:500
},
"& .tableCellData":{
  fontFamily: "Poppins",
fontSize: "16px",
fontWeight: 400,
lineHeight: "24px",
width:"10%", 
color:'#44403C'
},
"& .addCatalogueBtn":{
    width: "167px",
    height: "45px",
    background: "#7F1187",
    color: "white",
    fontFamily: "poppins",
    borderRadius: "45px", 
    fontWeight: 500, 
    textTransform: "none", 
    fontSize: "16px",
    "&:hover": {
      background: "#7F1187"
    }
},
"& .mainContainer": {
  borderRadius:"10px",
  border:"1px solid lightgrey",
  borderBottom:"1px solid transparent",
  "@media(max-width:1100px)": {
    width:"100%",
    overflowX:"scroll"
 },
},
"& .mainHeading":{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    padding:"2rem"
},
"& .valueStyle":{
    display:"flex",
    alignItems:"center",
    lineHeight: "54px",
fontWeight:600, color:'#292524',
fontFamily: "poppins",
fontSize: "16px",
},
"& .offerStyle":{
    color:"#059669",
    backgroundColor:"#0596691C",
    padding:"10px",
    borderRadius:"8px"
}
})
  // Customizable Area End
