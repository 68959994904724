import React from "react";
import { styled, Box } from "@mui/material";
import HeaderVendor from "./HeaderVendor.web"
import Footer from "./Footer.web";

const backgroundImage = require("./assets/background_image.png");

interface IHeaderVendorFooter {
  navigation: any;
  children: React.ReactNode;
}

const HeaderVendorFooter: React.FC<IHeaderVendorFooter> = ({
  navigation,
  children,
}) => {
  return (
    <HeaderVendorFooterStyle data-test-id="InspirationStyle">
      <HeaderVendor className="header" navigation={navigation} isOtherPage />
      <Box className="body-data">
        {children}
        <Footer className="footer" />
      </Box>
    </HeaderVendorFooterStyle>
  );
};

export default HeaderVendorFooter;

const HeaderVendorFooterStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "1 1 auto",
  "& .header": {
    flex: "0 0 auto",
  },
  "& .body-data": {
    backgroundImage: `url(${backgroundImage})`,
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
  },
  "& .footer": {
    flex: "0 0 auto",
  },
}));
