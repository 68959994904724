import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import React, {ChangeEvent} from 'react'
import { toast } from "react-toastify";
export interface FormDataOption {
  reference_id: string;
  email: string;
  fullName: string;
  gstNumber:string;
  businessName:string;
  businessCategory:string;
  primaryContact:string;
  secondaryNumber:string;
  showPassword: boolean;
  pincode:string;
  serviceProvided:string;
  phoneId:string;
  city:string;
  state:string;
  country:string;
  productOffered:string;
  branchName:string;
  ifscCode:string;
  bankAccountDetails:string;
  paymentMethod:string;
  phoneIdNo:string;
  serviceLocation:string;
  address:string;
  portfolioLink:string;
  primaryContactPersonName:string;
  description:string;
  selectedLocation:string,
  addLink:Link[],
  addProduct:Product[]
};
interface Link{
  link:string
}
interface Product{
  product:string
}
interface CountryData {
  label: string;
  value: number;
}
export interface ColorOption {
  label: string;
  value: number;
}
interface IReference {
  id: number;
  name: string;created_at: string;
  updated_at: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  formData:FormDataOption;  
  isEditing:boolean;
  locationData:ColorOption[];
  selectedLocations: ColorOption[];
  token:string;
  countryData:CountryData[];
  stateData:CountryData[];
  cityData:CountryData[];
  showName:string;
  uploadImage:File | null;
  phoneNumberData:Array<object>;
  serviceData:IReference[]

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateSettingApiCallId:string="";
  serviceLocationApiCallId:string="";
  countryDataApiCallId:string="";
  stateDataApiCallId:string="";
  cityDataApiCallId:string="";
  settingDataApiCallId:string=""
  serviceProvidedApicallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      formData: {
        email: "",
        fullName: "",
        businessCategory:"",
        serviceLocation:"",
        bankAccountDetails:"",
        branchName:"",
        gstNumber:"",
        ifscCode:"",
        businessName:"",
        paymentMethod:"",
        pincode:"",
        state:"",
        country:"",
        primaryContact:"",
        address:"",
        city:"",
        secondaryNumber:"",
        showPassword: false,
        reference_id: "",
        serviceProvided:"",
        productOffered:"",
        portfolioLink:"",
      primaryContactPersonName:"",
      phoneId:'',
      phoneIdNo:'',
      description:"",
      selectedLocation:"",
      addLink:[{link:""}],
      addProduct:[{product:""}]
      },
      isEditing:true,
      locationData:[],
      selectedLocations:[],
      token:"",
      countryData:[],
      stateData:[],
      cityData:[],
      showName:"",
      uploadImage: null,
      phoneNumberData :[{label:"a", value:"+91"},{label:"b", value:"+01"},{label:"c", value:"+90"}],
      serviceData:[]

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.serviceLocationApiCallId:
          const mappedLocations = responseJson.data.map((location: any) => ({
            value: location.id,
            label: location.attributes.location_name
          }));
          this.setState({ locationData: mappedLocations });
          break;
          case this.countryDataApiCallId:
          const mappedCountryData = responseJson.data.map((country: any) => ({
            value: country.country_code,
            label: country.country_full_name
          }));
          this.setState({ countryData: mappedCountryData });
          break;
          case this.stateDataApiCallId:
          const mappedStateData = responseJson.data.map((state: any) => ({
            value: state.state_code,
            label: state.state_name
          }));
          this.setState({ stateData: mappedStateData });
          break;
          case this.serviceProvidedApicallId:
            if (responseJson) {
              this.setState({
                serviceData: responseJson,
              });
            }
            break;
          case this.cityDataApiCallId:
          const mappedCityData = responseJson.data.map((city: any) => ({
            value: city?.city_name,
            label: city?.city_name
          }));
          this.setState({ cityData: mappedCityData });
          break;
          case this.settingDataApiCallId:
          const attributes = responseJson.data.attributes
          const addProduct = await Promise.all(
            attributes.products_offered?.map(async (data:string) => ({
              product: data || '',
            })) || []
          );
          const addLink = await Promise.all(
            attributes.social_media_links?.map(async (link:string) => ({
              link: link || '',
            })) || []
          );
          this.setState((prevState:any) => {
            return {
              formData: {
                ...prevState.formData,
                email: attributes.email || "",
                fullName: "",
                businessCategory:"",
                serviceLocation:"",
                bankAccountDetails: attributes.account_number,
                branchName: attributes.branch_name,
                gstNumber:attributes.GST_number,
                ifscCode: attributes.ifsc_code,
                businessName:attributes.business_name,
                paymentMethod: attributes.bank_name,
                pincode:"",
                state:"",
                country:"",
                primaryContact:attributes.primary_contact_phone_number,
                address:responseJson.vendor_address,
                city:"",
                secondaryNumber:attributes.secondary_contact_no,
                showPassword: false,
                reference_id: "",
                serviceProvided: attributes.service_provided && attributes.service_provided.id,
                productOffered:"",
                portfolioLink: attributes.portfolio_link,
                primaryContactPersonName:"",
                phoneId:attributes.primary_contact_country_code,
                phoneIdNo: attributes.secondary_contact_country_code,
                description: attributes.business_detail_description,
                selectedLocation:"",
                addLink,
                addProduct
                      },
                    };
                  });
                  const uploadImageUrl = attributes.profile_picture
                  const uploadImageFile = await this.urlToFile(uploadImageUrl, "size_chart.png", "image/png");
                  this.setState({showName : attributes.full_name, uploadImage: uploadImageFile})
                  
                  break;
                  case this.updateSettingApiCallId:
                    if(!responseJson.errors){
                      toast.success("Edit Catalogue Sucessfully");
                    }
                    break;
                default:
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // Customizable Area Start

  componentDidMount = async () => {
    this.serviceLoationApi();
    this.getToken()
    this.getAllCountryApi();
    this.getAllSettingData()
    this.getServiceDataList()
  };
  urlToFile = async(url: RequestInfo, filename: string, mimeType: string) =>  {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], filename, { type: mimeType });
  }
  handleEdit = () => {
    this.setState({isEditing:true})
  }
  getServiceDataList = async () => {
    this.serviceProvidedApicallId = await apiCall({
      endPoint: "account_block/services",
      method: "GET",
      contentType: "application/json",
    });
  };
  handleEditApiCall = async (values:FormDataOption) => {
let formData = new FormData();
  formData.append("full_name",this.state.showName);
formData.append("business_name", values.businessName);
formData.append("GST_number", values.gstNumber);
formData.append("service_id", "1");
formData.append("primary_contact_no", values.primaryContact);
formData.append("email", values.email);
formData.append("secondary_contact_no",values.secondaryNumber);
formData.append("address_params[address]", values.address);
formData.append("address_params[country]", values.country);
formData.append("address_params[state]", values.state);
formData.append("address_params[city]", values.city);
formData.append("address_params[pincode]", values.pincode);
formData.append("portfolio_link", values.portfolioLink);
formData.append("business_detail_description", values.description);
formData.append("bank_name", values.paymentMethod);
formData.append("account_number", values.bankAccountDetails);
formData.append("ifsc_code", values.ifscCode);
formData.append("branch_name", values.branchName);
if (this.state.uploadImage) {
  formData.append("profile_picture", this.state.uploadImage);
  }
if (Array.isArray(values.selectedLocation)) {
values.selectedLocation.forEach((spec: any) => {
formData.append("address_params[service_location_ids]", spec.value);
});
} 
values.addLink.forEach((item: Link) => {
formData.append(`social_media_links[]`, item.link);
});
values.addProduct.forEach((item: Product) => {
  formData.append(`products_offered[]`, item.product);
  });

  this.updateSettingApiCallId = await apiCall({
    endPoint: "account_block/accounts/update",
    method: "PATCH",
    body: formData,
  });
  }
  handleSelectLocationChange = (event: React.ChangeEvent<{}>, newValue: ColorOption[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    this.setState({ selectedLocations: newValue });
    setFieldValue('selectedLocation', newValue);
  };

handleDeleteLocationChip = (optionToDelete: ColorOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
  const newSelectedLocation = this.state.selectedLocations.filter(
    (item) => item.value !== optionToDelete.value
  );
  this.setState({ selectedLocations: newSelectedLocation });
  setFieldValue('selecredLocation', newSelectedLocation);
};
toggleLocationOption = (optionToToggle: ColorOption) => {
  this.setState((prevState) => {
    const { selectedLocations } = prevState;
    const isSelected = selectedLocations.some(option => option?.value === optionToToggle?.value);
    if (isSelected) {
      return {
        selectedLocations: selectedLocations.filter(option => option?.value !== optionToToggle?.value),
      };
    } else {
      return {
        selectedLocations: [...selectedLocations, optionToToggle],
      };
    }
  });
};
handleLocationOption = (option: ColorOption,isSelected:any,  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
  const newSelectedLocation = isSelected
              ? this.state.selectedLocations.filter(
                  (selected) => selected.value !== option.value
                )
              : [...this.state.selectedLocations, option];
            this.setState({ selectedLocations: newSelectedLocation });
            setFieldValue('selectedLocation', newSelectedLocation);
}
serviceLoationApi = async() => {
  this.serviceLocationApiCallId = await apiCall({
    endPoint: "bx_block_multipageforms2/service_locations",
    method: "GET",
    token: this.state.token,
  });
}
getToken = async () => {
  const token = await getStorageData("authToken");
  if (token) {
    this.setState({
      token,
    });
  } else {
    this.props.navigation.goBack();
  }
};
getAllCountryApi = async () => {
  this.countryDataApiCallId = await apiCall({
    endPoint: "account_block/accounts/country_code_and_flags/fetch_countries",
    method: "GET",
    token: this.state.token,
  });
}
getAllStateApi = async (selectedCountry:string) => {
  this.stateDataApiCallId = await apiCall({
    endPoint: `account_block/accounts/country_code_and_flags/fetch_states?country_code=${selectedCountry}`,
    method: "GET",
    token: this.state.token,
  });
}
getCityApiCall = async (selectedCountry:string,selectedCity:string) => {
  this.cityDataApiCallId = await apiCall({
    endPoint: `account_block/accounts/country_code_and_flags/fetch_cities?country_code=${selectedCountry}&state_code=${selectedCity}`,
    method: "GET",
    token: this.state.token,
  });
}
handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({ showName: event.target.value });
};
handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0];
  if(file){
      this.setState({uploadImage: file});
  }
};
getAllSettingData = async() => {
  const token = await getStorageData("authToken");
  this.settingDataApiCallId = await apiCall({
    endPoint: "account_block/accounts/id",
    method: "GET",
    token: token,
  });
}
  // Customizable Area End
}
