import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  formData: {
    email: string;
    currentPassword: string;
    newPassword:string
    fullName: string;
    number:string;
    profile:string;
    showPassword: boolean,
    showNewPassword:boolean;
    contactNumber:string;
    phoneId:string;
  };
  isEditing: boolean;
  value: string;
  phoneNumberData:Array<object>
  showData:boolean;
  selectedImage: File | null;
  isEditingImage:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userProfileApiCallId:string="";
  getUserPofileCallId:string="";
  isFormikValueSet:boolean=false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
       getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      formData: {
        email: "",
        currentPassword: "",
        newPassword:"",
        fullName: "",
        profile:"",
        showPassword: false,
        showNewPassword:false,
        contactNumber:"",
        number:"",
      phoneId:'',
      },
      isEditing: false,
      value: 'Initial Read-Only Value',
      selectedImage: null,
      phoneNumberData:[{label:"a", value:"+91"},{label:"b", value:"+01"},{label:"c", value:"+90"}],
      showData:false,
      isEditingImage: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        
      );
      if(webApiRequestCallId === this.getUserPofileCallId || webApiRequestCallId === this.userProfileApiCallId){
        this.setState({formData:{
          email: webResponseJson.data.attributes.email,
          currentPassword: "",
          newPassword:"",
          fullName:  webResponseJson.data.attributes.full_name,
          contactNumber:webResponseJson.data.attributes.full_phone_number,
          number:"",
          phoneId:"",
          showPassword: false,
          showNewPassword:false,
          profile:webResponseJson.data.attributes.profile_picture
        },isEditing:false,showData:true})
    }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getUserProfileData();
  }

  submitFormData = (values: any) => {
    this.setState({ isEditing: false, isEditingImage: false }); 
  this.updateUserProfileInfo(values);
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
            this.setState({ selectedImage: file,formData:{...this.state.formData,profile:URL.createObjectURL(file)} });
    }
};

handleEdit = () => {
  this.setState({ isEditing: true , isEditingImage:true});
};

toggleEdit = () => {
  this.setState({ isEditing: !this.state.isEditing });
};

  handleClick = (setFieldValue: any, values: any) =>
    setFieldValue(
      "showPassword",
      !values.showPassword
    )

  handleClickNewPassword = (setFieldValue: any, values: any) =>
    setFieldValue(
      "showNewPassword",
      !values.showNewPassword
    )

updateUserProfileInfo = async (values:any) => {
  let token = localStorage.getItem("authToken")
  let header:any = {
    ContentType:"multipart/form-data",
    token
  };
  const formData = new FormData();
  formData.append("full_name",values.fullName);
  formData.append("email",values.email);
  this.state.selectedImage && formData.append("profile_picture",this.state.selectedImage);
  formData.append("current_password",values.currentPassword);
  formData.append("new_password",values.newPassword);
  formData.append("full_phone_number",values.contactNumber)

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.userProfileEndpoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  ); this.userProfileApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPatchMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

getUserProfileData = async () => {
  let token = localStorage.getItem("authToken")
  let header:any = {
    "Content-Type": "application/json",
    token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getUserProfileEndpoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  ); this.getUserPofileCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};


handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const { name, value } = e.target;
  this.setState((prevState) => ({
    formData: {
      ...prevState.formData,
      [name]: value
    }
  }));
};

  // Customizable Area End
}
