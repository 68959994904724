import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface FormDataOption {
couponCode:string;
expDate:string;
orderValue:string;
maxDiscount:string;
offer:string;
termsPolicy:string
}
interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
 formData:FormDataOption;
isEditing:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CreateVendorOfferController extends BlockComponent<Props, S, SS> {
  createOfferApiCallId:string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      isEditing: false,
      formData:{
          couponCode: "",
          expDate: "",
          orderValue: "",
          maxDiscount: "",
          offer: "",
          termsPolicy: ""
      }
      
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    const isEditing =  await getStorageData('editVendorOffer');
    if(isEditing == "true"){
      this.setState({isEditing:true})
      removeStorageData('editVendorOffer')
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );

    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
       if(apiRequestCallId === this.createOfferApiCallId){
         if(responseJson.meta){
          toast.success(responseJson.meta.message);
          const addCatalogueForm: Message = new Message(getName(MessageEnum.NavigationMessage));
    addCatalogueForm.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriptionVendor");
    addCatalogueForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(addCatalogueForm);
         }
         else{
          toast.error(responseJson.errors.coupon_code[0])
         }
        }
      console.log(apiRequestCallId, responseJson);
    // Customizable Area End
  }

  // Customizable Area Start
  createOfferApi = async (values:FormDataOption) => {
    const httpBody = {
      "discount_and_offer": {
          "coupon_code": values.couponCode,
          "exp_date": values.expDate,
          "min_order_value": values.orderValue,
          "max_discount": values.maxDiscount,
          "discount_percentage": values.offer,
          "terms_policies": values.termsPolicy
      }
  }
    this.createOfferApiCallId = await apiCall({
      endPoint: "bx_block_discount/checkout_addition_costs",
      method: "POST",
      token: this.state.token,
      body:JSON.stringify(httpBody),
      contentType: "application/json",

    });
  }
  // Customizable Area End
}
