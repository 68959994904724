import React from "react";
// Customizable Area Start
import { Box, Button, Typography,IconButton,Menu, styled, Select, MenuItem, Card, Table, TableHead, TableRow, TableBody, TableCell, Avatar, Rating }
  from "@mui/material";
import HeaderVendorFooter from "../../../../packages/components/src/HeaderVendorFooter";
import { logo1, logo2, logo3, logo4, avatarImg } from "./assets";
import InfoIcon from '@mui/icons-material/Info';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MoreVertIcon from '@mui/icons-material//MoreVert';
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  cardData = [
    {
      background: "#EAF6F0",
      imgSrc: logo1,
      title: "Total Catalogue",
      value: "128",
    },
    {
      background: "#F0ECFD",
      imgSrc: logo4,
      title: "Total Sales",
      value: "₹ 12080",
      fontFamily: "roboto",
    },
    {
      background: "#E9F6FC",
      imgSrc: logo2,
      title: "New Orders",
      value: "128",
    },
    {
      background: "#F6F1EA",
      imgSrc: logo3,
      title: "New Inquiry",
      value: "12",
    },
  ];

  rowData = [
    {
      id: 1,
      code: "678976",
      date: "14 Jan 2024",
      quantity: "06",
      price: "₹30,000",
      status: "Yes",
      textColor: "green",
      statusColor: "#0596691C",
      location: "Pali hill, Mum..",
      completion: "Completed",
      completionColor: "#0596691C",
    },
    {
      id: 2,
      code: "123456",
      date: "15 Feb 2024",
      quantity: "12",
      price: "₹40,000",
      status: "No",
      textColor: "red",
      statusColor: "#DC26261A",
      location: "Andheri, Mum..",
      completion: "Pending",
      completionColor: "#DC26261A",
    },
    {
      id: 3,
      code: "123456",
      date: "15 Feb 2024",
      quantity: "12",
      price: "₹40,000",
      status: "No",
      textColor: "green",
      statusColor: "#0596691C",
      location: "Andheri, Mum..",
      completion: "Pending",
      completionColor: "#0596691C",
    },
    {
      id: 4,
      code: "123456",
      date: "15 Feb 2024",
      quantity: "12",
      price: "₹40,000",
      status: "No",
      textColor: "red",
      statusColor: "#DC26261A",
      location: "Andheri, Mum..",
      completion: "Pending",
      completionColor: "#DC26261A",
    },
    {
      id: 5,
      code: "123456",
      date: "15 Feb 2024",
      quantity: "12",
      price: "₹40,000",
      status: "No",
      textColor: "green",
      statusColor: "#0596691C",
      location: "Andheri, Mum..",
      completion: "Pending",
      completionColor: "#0596691C",
    }
  ];

  reviews = [
    {
      id: 1,
      name: "Sonali Thakur (MUM)",
      avatarPic: avatarImg,
      time: "Just now",
      reviewText: "Awesome! product",
      rating: 8.3,
      description: "The charming floral applique motifs in bright colors embolden the pastel base of the gorgeous lehenga. the scallop details on the dupatta features.."
    },
    {
      id: 2,
      name: "Sonali Thakur (MUM)",
      avatarPic: avatarImg,
      time: "Just now",
      reviewText: "Nice! product",
      rating: 7.4,
      description: "The intricate embroidery in shimmering gold threads gracefully enhances the rich maroon base of this."
    },
    {
      id: 3,
      name: "Sonali Thakur (MUM)",
      avatarPic: avatarImg,
      time: "Just now",
      reviewText: "Great Choice!",
      rating: 8.3,
      description: ""
    },
    {
      id: 4,
      name: "Sonali Thakur (MUM)",
      avatarPic: avatarImg,
      time: "Just now",
      reviewText: "Excellent!",
      rating: 8.3,
      description: ""
    }
  ];
  // Customizable Area End

  render() {
    // Customizable Area Start
    const open = Boolean(this.state.anchorEl); 
    return (
      //Merge Engine DefaultContainer
      <HeaderVendorFooter navigation={this.props.navigation}>
        <CustomBox>
          <Box className="mainContainer">
            <Box className="overviewBox">
              <Typography className="overviewText">Over View</Typography>
              <Select
                className="overviewSelect"
                labelId="time-period-select"
                displayEmpty
                defaultValue=""
              >
                <MenuItem value="">This week</MenuItem>
                <MenuItem value="this-month">This month</MenuItem>
                <MenuItem value="this-year">This year</MenuItem>
              </Select>
            </Box>
          </Box>
          <Box
            className="cardBox">
            {this.cardData.map((card, index) => (
              <Card
                key={index}
                style={{
                  background: card.background,
                  width: "316px",
                  height: "186px",
                }}
              >
                <Box sx={{ padding: "1rem" }}>
                  <img className="header-logo" src={card.imgSrc} alt={`logo${index + 1}`} />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography style={{ fontSize: "16px", fontWeight: 600, fontFamily: "Poppins" }}>
                      {card.title}
                    </Typography>
                    <InfoIcon style={{ width: "13.33px", height: "13.33px", padding: "1rem" }} />
                  </Box>
                  <Typography style={{ fontSize: "36px", fontFamily: card.fontFamily || "Poppins", fontWeight: 600 }}>
                    {card.value}
                  </Typography>
                </Box>
              </Card>
            ))}
          </Box>

          <Box className="orderManagementBox">
            <Box className="orderManagementInnerBox">
              <Typography className="orderManagementText">Order Management</Typography>
              <Table aria-label="simple table" className="table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className="tableHeaderText">#</TableCell>
                    <TableCell align="center" className="tableHeaderText">Order Id</TableCell>
                    <TableCell align="center" className="tableHeaderText">Date</TableCell>
                    <TableCell align="center" className="tableHeaderText">Name</TableCell>
                    <TableCell align="center" className="tableHeaderText">Price</TableCell>
                    <TableCell align="center" className="tableHeaderText">Paid</TableCell>
                    <TableCell align="center" className="tableHeaderText">Addess</TableCell>
                    <TableCell align="center" className="tableHeaderText">Status</TableCell>
                    <TableCell align="center" className="tableHeaderText">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.rowData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center" className="tableRowText">{row.id}</TableCell>
                      <TableCell align="center" className="tableRowText">{row.code}</TableCell>
                      <TableCell align="center" className="tableRowText">{row.date}</TableCell>
                      <TableCell align="center" className="tableRowText">{row.quantity}</TableCell>
                      <TableCell align="center" className="tableRowText">{row.price}</TableCell>
                      <TableCell align="center" className="tableRowText">
                        <Box sx={{ width: "49px", height: "32px", background: row.statusColor, color: row.textColor }}>
                          {row.status}
                        </Box>
                      </TableCell>
                      <TableCell align="center" className="tableRowText">{row.location}</TableCell>
                      <TableCell align="center" className="tableRowText">
                        <Box sx={{ width: "107px", height: "32px", background: row.completionColor, color: row.textColor }}>
                          {row.completion}
                        </Box>
                      </TableCell>
                      <TableCell align="center" className="tableRowText">
                        <ChatBubbleOutlineIcon />
                        <IconButton onClick={this.handleClick}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={this.state.anchorEl}
                          open={open}
                          onClose={this.handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <MenuItem onClick={this.handleClose}>Edit</MenuItem>
                          <MenuItem onClick={this.handleClose}>Delete</MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>

            <Box className="reviewBox">
              <Box className="reviewInnerBox">
                <Typography className="reviewText">Latest Reviews</Typography>
                <Button className="seeallBtn">See all</Button>
              </Box>
              <Box>
                {this.reviews.map((review) => (
                  <Box key={review.id} className="reviewCard">
                    <Box className="reviewInnerCard">
                      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                        <Avatar src={review.avatarPic} />
                        <Typography className="reviewCardname">{review.name}</Typography>
                      </Box>
                      <Typography className="reviewCardTime">{review.time}</Typography>
                    </Box>

                    <Box className="commentBox">
                      <Typography className="commentext">{review.reviewText}</Typography>
                      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                        <Rating value={1} max={1} precision={1} readOnly />
                        <Typography className="rating">{review.rating}/10</Typography>
                      </Box>
                    </Box>

                    <Typography className="reviewDescription">
                      {review.description}
                    </Typography>
                  </Box>
                ))}
              </Box>

            </Box>

          </Box>
        </CustomBox>
      </HeaderVendorFooter>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" }
};

const CustomBox = styled(Box)({
  "& .mainContainer": {
    display: "flex",
    justifyConetent: "center",
    alignItems: "center",
    width: "100vw",
    height: "auto",
    padding: "0rem 4rem"
  },
  "& .tableHeaderText": {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "poppins",
    color: "#78716C",
  },
  "& .tableRowText": {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#44403C",
    borderBottom: "transparent"
  },
  "& .overviewBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90vw"
  },
  "& .overviewText": {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .overviewSelect": {
    width: "124px",
    height: "40px",
    color: "#6F767E"
  },
  "& .cardBox": {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    gap:"2rem",
    paddingTop: "2rem",
    flexWrap: "wrap",
    "@media (min-width: 768px)": {
      flexDirection: "row",
    },
    "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  "& .orderManagementBox": {
    flexWrap: "Wrap",
    display: "flex",
    padding: "3rem 0",
    flexDirection: "row",
    gap: "1rem",
    "@media (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  "& .orderManagementInnerBox": {
  padding: "1rem 4rem",
  width: "70vw",
  display: "flex",
  flexDirection: "column",
  "@media (max-width: 1510px)": {
    width: "90vw",
  },
  "@media (max-width: 900px)": {
    width: "80vw",
    overflowX: "auto",             
    WebkitOverflowScrolling: "touch" 
  },
},
  "& .orderManagementText": {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .reviewBox": {
    width: "19.8vw",
    paddingRight: "10px",
    "@media (max-width: 1510px)": {
      width: "100vw",
      paddingLeft: "3rem",
      paddingRight: "3rem"
    },
    "@media (max-width: 768px)": {
      width: "90vw",
      paddingLeft: "2rem",
      paddingRight: "2rem"
    },
  },
  "& .reviewInnerBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .reviewText": {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .table": {
    border: "1px solid lightgrey",
    borderRadius: "12px",
  },
  "& .seeallBtn": {
    color: "#7F1187",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "poppins"
  },
  "& .reviewCard": {
    borderBottom: "1px solid #CBD5E1",
    paddingBottom: "1rem",
  },
  "& .reviewInnerCard": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .reviewCardname": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#292524"
  },
  "& .reviewCardTime": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#292524"
  },
  "& .commentBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "1rem"
  },
  "& .commentText": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#292524"
  },
  "& .rating": {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "poppins",
    color: "#78716C"
  },
  "& .reviewDescription": {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "poppins",
    color: "#A8A29E",
    padding: "0.7rem 0"
  }

})
// Customizable Area End
